import React, { useState } from "react";
import Select from "../../widgets/Select";
import { getExchangeConfig } from "../../helper/platformConfig";

const defaultOption = {
  label: "All (default)",
  value: "all",
};

const SegmentFilter = ({ data, onFilterchange }) => {
  const [selectedSegment, setSelectedSegment] = useState(defaultOption);

  const handleSegment = (option) => {
    setSelectedSegment(option);
    onFilterchange("segment", option);
  };

  return (
    <>
      <Select
        label={getExchangeConfig().listingFilters[0]}
        className="w-52 xl:w-60"
        defaultOptions={data}
        value={selectedSegment}
        onChange={handleSegment}
      />
    </>
  );
};

export default SegmentFilter;
