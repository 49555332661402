import React from "react"
import {components} from "react-select"
import AsyncSelect from "react-select/async"
import IconDropDown from "../assets/icons/IconDropDown"

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <IconDropDown />
    </components.DropdownIndicator>
  );
};

const Select = ({
  label = "",
  className,
  id,
  value,
  placeholder,
  defaultOptions,
  onChange,
  mandatory,
  loadOptions,
  mobCode,
  isSearchable = false,
  onInputChange = () => {},
  error,
  isMulti = false,
  ...other
}) => {
  const customStyles = {
    multiValueRemove: (base, state) => {
      return state.data.isFixed ? { ...base, display: "none" } : base
    },
    control: (provided, { isFocused }) => ({
      ...provided,
      minHeight: other.isMulti ? 50 : 50,
      maxHeight: "initial",
      borderRadius: isFocused ? "8px 8px 0 0" : "8px",
      padding: "0px 10px 0px 10px",
      border: "1px solid #707070 !important",
      boxShadow: "none",
    }),
    option: (provided, { isFocused }) => ({
      ...provided,
      fontSize: 16,
      fontWeight: 700,
      background: isFocused ? "#4EB73F" : "transparent",
      color: isFocused ? "#ffffff" : "#00000",
      borderBottom: "1px solid #707070 ",
      "&:last-child": {
        borderBottom: "0px !important",
      },
      "&:hover": {
        background: "#4EB73F",
      },
    }),
    noOptionsMessage: (base) => ({
      ...base,
      fontSize: 16,
      fontWeight: 700,
      color: "#000000",
    }),
    valueContainer: (provided) => ({
      ...provided,
      fontSize: 16,
      fontWeight: 700,
      color: "#000000",
      flexWrap: "wrap",
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: "250px",
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "-1px",
      boxShadow: "none",
      borderRadius: " 0 0 8px 8px",
      border: "1px solid #707070 !important",
      zIndex: "9999",
    }),
    indicatorSeparator: (styles) => {
      return {
        ...styles,
        display: "none",
      }
    },
    indicatorsContainer: (provided) => ({
      ...provided,
      display: "flex",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      height: "34px",
      minHeight: "34px",
      maxHeight: "34px",
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontWeight: 600,
    }),
  }
  return (
    <div className={`relative ${className}`}>
      {label?.length && (
        <label className="absolute -top-4 left-3 px-2 text-base text-black z-[1] bg-white">
          {label} {mandatory ? <span className="text-red">*</span> : ""}
        </label>
      )}
      <div className="relative flex items-center w-full">
        <AsyncSelect
          className="w-full"
          styles={customStyles}
          isSearchable={isSearchable}
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onInputChange={onInputChange}
          loadOptions={loadOptions}
          defaultOptions={defaultOptions}
          isMulti={isMulti}
          closeMenuOnSelect={!isMulti}
          components={{DropdownIndicator}}
          {...other}
        />
      </div>
    </div>
  )
}

export default Select
