import React, { useState } from "react";
import Select from "../../widgets/Select";
import { getExchangeConfig } from "../../helper/platformConfig";

const defaultOption = {
  label: "All (default)",
  value: "all",
};

const TypeFilter = ({ data, onFilterchange }) => {
  const [selectedType, setSelectedType] = useState(defaultOption);

  const handleType = (option) => {
    setSelectedType(option);
    onFilterchange("type", option);
  };
  return (
    <>
      <Select
        label={getExchangeConfig().listingFilters[1]}
        className="w-52 xl:w-60"
        defaultOptions={data}
        value={selectedType}
        onChange={handleType}
      />
    </>
  );
};

export default TypeFilter;
