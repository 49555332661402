import React, { useEffect } from "react";
import Image from "../widgets/Image";
import Logo from "../assets/images/zero13-logo.png";
import Favicon from "../assets/images/zero13-favicon.png";
import MetaDetails from "../widgets/MetaDetails";
import { useNavigate } from "react-router-dom";
import OktaSignInWidget from "../components/Okta/OktaSignInWidget";
import { jwtDecode } from "jwt-decode";

const Authentication = () => {
  const navigate = useNavigate();

  const authenticatedHandler = (authResult) => {
    const accessToken = authResult.accessToken;
    if (accessToken) {
      const tokenDetails = jwtDecode(accessToken);
      localStorage.setItem(
        "okta-token-storage",
        JSON.stringify({ accessToken, tokenDetails })
      );
      navigate("/", { replace: true });
    }
  };
  useEffect(() => {
    const auth0Lock = require("../helper/auth0").auth0Lock;
    auth0Lock.show();
    auth0Lock.on("authenticated", authenticatedHandler);
    return () => {
      auth0Lock.off("authenticated", authenticatedHandler);
    };
  }, []);

  return (
    <>
      <MetaDetails title="ZERO13" description="ZERO13" favicon={Favicon} />
      <div className={`relative h-screen bg-white flex-center`}>
        <div className="absolute top-0 left-0 h-2/4 w-full bg-welcomeBackgroundTopHalf" />
        <div className="relative container z-10 max-lg:mt-14">
          <div className="grid lg:grid-cols-2 gap-14 lg:gap-16 2xl:gap-40">
            <div className="relative max-lg:text-center md:-top-10">
              <Image
                src={Logo}
                alt="Zero13 Logo"
                title="Zero13"
                className="h-14 max-lg:mx-auto"
              />
              <h1 className="text-2xl font-bold text-white max-w-md max-lg:mx-auto mb-9 mt-7 md:mt-7 lg:mt-8 font-quicksand">
                Digitally interconnecting ESG markets to achieve Net Zero
              </h1>
              <p className="text-sm 2xl:text-base text-white md:text-black font-quicksand">
                The COP28 award winning automated AI and blockchain-driven
                ‘Network of Networks’ PaaS ecosystem for carbon credits and ESG
                real-world assets interoperable across multiple APIs and
                blockchains
              </p>
            </div>
            <OktaSignInWidget />
          </div>
        </div>
      </div>
    </>
  );
};

export default Authentication;
