import React from "react"

const LiningTitle = ({ title, level }) => {
  const TagName = `h${Math.max(1, Math.min(6, level))}`

  return (
    <div className="relative flex items-center">
      <TagName className="relative pr-10 bg-white text-2xl font-bold text-black z-[1]">
        {title}
      </TagName>
      <span className="absolute right-0 w-full h-[1px] bg-cD9"></span>
    </div>
  )
}

export default LiningTitle
