import { render } from "@testing-library/react";
import Table from "./Table";

test("check rendering table", () => {
  render(
    <Table
      columns={[{ accessorKey: "testing", header: () => "testing" }]}
      data={[{ testing: "hello" }]}
    />,
  );
  // expect(screen.getByText("Page title")).toBeInTheDocument();
});
