import React, { useState } from "react";
import Select from "../../widgets/Select";

const defaultOption = {
  label: "None",
  value: "none",
};

const DocumentSort = ({ data, onFilterchange }) => {
  const [selectedType, setSelectedType] = useState(defaultOption);

  const handleType = (option) => {
    setSelectedType(option);
    onFilterchange("type", option);
  };
  return (
    <>
      <Select
        label="Sort By"
        className="w-52 xl:w-60"
        defaultOptions={data}
        value={selectedType}
        onChange={handleType}
      />
    </>
  );
};

export default DocumentSort;
