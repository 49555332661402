import React from "react";
import LiningTitle from "../../widgets/LiningTitle";
import Image from "../../widgets/Image";
import DOMPurify from "dompurify";

const TopDetailBox = ({ detail }) => {
  return (
    <>
      {detail && (
        <div className="relative shadow-card rounded-xl">
          <Image
            src={detail.banner}
            className="object-cover w-full h-auto max-h-[200px] rounded-t-xl"
          />
          <div className="relative flex flex-col gap-3 py-5 px-8 bg-thingray">
            <h1 className="text-2xl text-black line-clamp-2">{detail.title}</h1>
            <div className="flex items-center divide-x-2 divide-gray/60">
              <p className="flex items-center gap-1 text-base text-black leading-none px-4 first:pl-0 last:pr-0">
                <span className="font-bold">Segment:</span>
                {detail.segment}
              </p>
              <p className="flex items-center gap-1 text-base text-black leading-none px-4 first:pl-0 last:pr-0">
                <span className="font-bold">Type:</span>
                {detail.type}
              </p>
              <p className="flex items-center gap-1 text-base text-black leading-none px-4 first:pl-0 last:pr-0">
                <span className="font-bold">Code:</span>
                {detail.symbol}
              </p>
            </div>
          </div>
          <div className="relative py-6 px-8 space-y-8">
            {detail.details.map((item, i) => (
              <div className="flex flex-col gap-5" key={i}>
                <LiningTitle level={2} title={item.title} />
                {/* <p className="text-lg text-black">{item.paragraph}</p> */}
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(`
                      <div class="reset-tw">
                      ${item.paragraph}
                      </div>`),
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default TopDetailBox;
