import moment from "moment";
const dModel = {
  parseList(data, accounts) {
    var items = [];
    data.forEach((element) => {
      const accountResult = accounts.filter(
        (val) => val.id === element["account-id"],
      );
      items.push({
        id: element.id,
        description: element.name,
        download: element.url,
        size: element.size,
        date: moment(element["date-published"]).format("D MMM YYYY"),
        account: accountResult.length > 0 ? accountResult[0].name : "",
        symbol: element["asset-code"] ? element["asset-code"] : "",
        type: element.type,
      });
    });
    return items;
  },
};
export default dModel;
