const iModel = {
  parseList(data) {
    var items = [];
    data.forEach((element) => {
      const quoteAsset = element["quote-asset"];
      items.push({
        id: element.id,
        img: "",
        title: element.description,
        segment: element.segment,
        type: element.type,
        symbol: element.code,
        ccy: quoteAsset.code,
        short: [],
        href: "/instrument/" + element.id,
      });
    });

    return items;
  },
  parseDetail(data) {
    let details = [];
    let short = [];
    if (data["long-text"]) {
      details = data["long-text"];
    }

    if (data["short-text"]) {
      short = data["short-text"];
    }

    if (data.items) {
      details = [];
      short = [];
      for (let index = 0; index < data.items.length; index++) {
        const element = data.items[index];
        if (element.type === "LONG") {
          details.push({
            title: element.label,
            paragraph: element.text,
          });
        }

        if (element.type === "SHORT") {
          short.push({
            label: element.label,
            description: element.text,
          });
        }
      }
    }

    return {
      banner: data.banner,
      logo: data.logo,
      details: details,
      short: short,
    };
  },
  parseSegment(data) {
    var items = [
      {
        label: "All (default)",
        value: "all",
      },
    ];
    data.forEach((element) => {
      items.push({
        label: element,
        value: element,
      });
    });
    return items;
  },
};

export default iModel;
