import React from "react";
import Image from "../widgets/Image";
import { Link } from "react-router-dom";
import routes from "../routes/routes";
import IconMenu from "../assets/icons/IconMenu";
import IconClose from "../assets/icons/IconClose";
import { getExchangeConfig } from "../helper/platformConfig";

const Header = ({ menu, setMenu }) => {
  return (
    <header className="relative flex items-center justify-between h-[70px] 2xl:h-[100px] bg-foreground shadow-head px-6 xl:px-9 z-10">
      <Link to={routes.listing}>
        <Image
          src={require(`../${getExchangeConfig().logo}`)}
          className="h-10 2xl:h-14"
          alt={`${getExchangeConfig().name} Logo`}
          title={getExchangeConfig().name}
        />
      </Link>
      <button
        className="relative flex-center lg:hidden text-black size-8"
        type="button"
        onClick={() => setMenu(!menu)}
      >
        <IconMenu
          className={`absolute size-8 ${menu ? "scale-0" : "scale-100"} transition-all`}
          strokeWidth={2}
        />
        <IconClose
          className={`absolute size-8 ${menu ? "scale-100" : "scale-0"} transition-all`}
          strokeWidth={2}
        />
      </button>
    </header>
  );
};

export default Header;
