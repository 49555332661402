import { render, screen } from "@testing-library/react";
import ListCard from "./ListCard";
import { BrowserRouter } from "react-router-dom";

test("check rendering type listing details", () => {
  render(
    <BrowserRouter>
      <ListCard
        title={"title 1"}
        segment={"segment"}
        type={"type  1"}
        symbol={"SYM"}
        ccy={"currency"}
        short={[{ label: "label 1", description: "description 1" }]}
      />
    </BrowserRouter>,
  );
  expect(screen.getByText("title 1")).toBeInTheDocument();
  expect(screen.getByText("segment")).toBeInTheDocument();
  expect(screen.getByText("type 1")).toBeInTheDocument();
  expect(screen.getByText("SYM")).toBeInTheDocument();
  expect(screen.getByText("currency")).toBeInTheDocument();
});
