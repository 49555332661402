import { createColumnHelper } from "@tanstack/react-table"
import IconDownload from "../assets/icons/IconDownload"

const columnHelper = createColumnHelper()

export const columns = [
  columnHelper.accessor("id", {
    header: () => "",
  }),
  columnHelper.accessor("account", {
    header: () => "",
  }),
  columnHelper.accessor("symbol", {
    header: () => "",
  }),
  columnHelper.accessor("type", {
    header: () => "",
  }),
  columnHelper.accessor("description", {
    header: () => "",
  }),
  columnHelper.accessor("size", {
    header: () => "",
  }),
  columnHelper.accessor("date", {
    header: () => "",
  }),
  columnHelper.accessor("action", {
    header: () => "",
    cell: (row) => (
      <a href={row.row.original.download} target="self">
        <IconDownload className="size-6" strokeWidth={2} />
      </a>
    ),
  }),
]