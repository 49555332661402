import React from "react";
import Image from "../../widgets/Image";
import Button from "../../widgets/Button";
import { useNavigate } from "react-router-dom";

const ListCard = ({
  id,
  img,
  title,
  segment,
  type,
  symbol,
  ccy,
  short,
  href,
}) => {
  const navigate = useNavigate();
  return (
    <div className="relative w-full h-full border-2 border-gray shadow-card hover:shadow-xl transition-all rounded flex flex-col justify-between min-h-[423px]">
      <div>
        <div className="relative p-2.5 border-b-2 border-gray">
          <Image
            src={img}
            className="w-full h-16 object-cover rounded overflow-hidden"
            alt={`${title} banner`}
            title={title}
          />
        </div>
        <div className="relative p-2">
          <h2 className="font-bold text-base text-black text-center mb-0.5 overflow-hidden text-ellipsis px-4 whitespace-nowrap">
            {title}
          </h2>
          <div className="relative">
            <div className="grid grid-cols-2 justify-between text-sm text-black px-1 py-0.5">
              <p className="font-bold">Segment:</p>
              <p className="text-right">{segment}</p>
            </div>
            <div className="grid grid-cols-2 justify-between text-sm text-black px-1 py-0.5">
              <p className="font-bold">Type:</p>
              <p className="text-right">{type}</p>
            </div>
            <div className="relative w-full h-0.5 bg-gray my-1.5" />
            <div className="grid grid-cols-2 justify-between text-sm text-black px-1 py-0.5">
              <p className="font-bold">Symbol:</p>
              <p className="text-right">{symbol}</p>
            </div>
            <div className="grid grid-cols-2 justify-between text-sm text-black px-1 py-0.5">
              <p className="font-bold">Currency: </p>
              <p className="text-right">{ccy}</p>
            </div>
            {short.map((item, i) => (
              <div
                className="grid grid-cols-2 justify-between text-sm text-black px-1 py-0.5"
                key={i}
              >
                <p className="font-bold">{item.label}:</p>
                <p className="text-right">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="relative p-2.5">
        <Button
          title="Detail"
          className="w-full"
          onClick={() => {
            navigate(href, {
              state: {
                title: title,
                segment: segment,
                type: type,
                symbol: symbol,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default ListCard;
