import React, { useEffect, useState } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const RangeFilter = ({ startDate, endDate, onFilterchange }) => {
  const [value, onChange] = useState([startDate, endDate]);

  useEffect(() => {
    onFilterchange("range", value);
  }, [value]);

  return (
    <div className="relative">
      <label className="absolute -top-4 left-3 px-2 text-base text-black z-[1] bg-white">
        Date Range{" "}
      </label>
      <div className="relative flex items-center w-full">
        <DateRangePicker onChange={onChange} value={value} />
      </div>
    </div>
  );
};

export default RangeFilter;
