import cx from "classnames";
import { Link } from "react-router-dom";

const Button = ({
  title,
  isLink = false,
  href = "/",
  className,
  onClick,
  size = "md",
  id,
  type = "button",
  isDisabled = false,
  icon,
}) => {
  const modeClasses = cx(
    className,
    `relative flex items-center gap-3 justify-center text-lg 2xl:text-xl font-bold text-white hover:text-black bg-primary hover:bg-secondary rounded text-center transition-all`,
    {
      [`py-2 2xl:py-[11px] px-4`]: size === "lg",
      [`py-1.5 px-4`]: size === "md",
    },
  );

  const InnerContent = () => {
    return (
      <>
        {icon}
        {title}
      </>
    );
  };

  return isLink ? (
    <Link
      to={href}
      onClick={onClick}
      className={modeClasses}
      disabled={isDisabled}
    >
      <InnerContent />
    </Link>
  ) : (
    <button
      onClick={onClick}
      type={type}
      className={modeClasses}
      id={id}
      disabled={isDisabled}
    >
      <InnerContent />
    </button>
  );
};

export default Button;
