import { jwtDecode } from "jwt-decode";

export const getAPIHeader = () => {
  let header = {
    "Content-Type": "application/json",
  };
  try {
    let tokenResult = localStorage.getItem("okta-token-storage");
    if (tokenResult) {
      let token = JSON.parse(tokenResult);
      if (token.accessToken) {
        header["authorization"] = `Bearer ${token.accessToken}`;
      }
    }
    return header;
  } catch (error) {
    return header;
  }
};

export const parseJWTDetail = (type) => {
  try {
    let dataID = "";
    let tokenResult = localStorage.getItem("okta-token-storage");
    if (tokenResult) {
      let token = JSON.parse(tokenResult);
      if (token.accessToken) {
        const data = jwtDecode(token.accessToken);
        if (type === "account") {
          dataID =
            data["https://zero13.net/userdata"]["accountid"];
        } else if (type === "account") {
          dataID =
            data["https://zero13.net/userdata"]["groupid"];
        } else {
          dataID =
          data["https://zero13.net/userdata"][
            "participantid"
          ];
        }
      }
    }
    return dataID;
  } catch (error) {
    return "";
  }
};

export const getUsername = () => {
  try {
    let dataID = "";
    let tokenResult = localStorage.getItem("okta-token-storage");
    if (tokenResult) {
      let token = JSON.parse(tokenResult);
      return token["tokenDetails"]["https://zero13.net/userdata"][
        "displayname"
      ];
    }
    return dataID;
  } catch (error) {
    return "";
  }
};
