import { render, screen } from "@testing-library/react";
import TypeFilter from "./TypeFilter";
import { getExchangeConfig } from "../../helper/platformConfig";

test("check rendering type filter", () => {
  render(<TypeFilter onFilterchange={() => {}} />);
  expect(
    screen.getByText(getExchangeConfig().listingFilters[1]),
  ).toBeInTheDocument();
});
