import { render, screen } from "@testing-library/react";
import TopDetailBox from "./TopDetailBox";

test("check rendering type listing details", () => {
  render(
    <TopDetailBox
      detail={{
        segment: "segment 1",
        type: "only type",
        symbol: "SYM",
        details: [{ title: "new title", paragraph: "paragraher" }],
      }}
    />,
  );
  expect(screen.getByText("segment 1")).toBeInTheDocument();
  expect(screen.getByText("only type")).toBeInTheDocument();
  expect(screen.getByText("new title")).toBeInTheDocument();
  expect(screen.getByText("SYM")).toBeInTheDocument();
  expect(screen.getByText("paragraher")).toBeInTheDocument();
});
