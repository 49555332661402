import React from "react"

const IconDropDown = ({ className }) => {
  return (
    <>
      <svg
        width="12"
        height="10"
        viewBox="0 0 12 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`text-current ${className}`}
      >
        <path
          d="M6.8137 8.86103C6.41487 9.41931 5.58513 9.41931 5.1863 8.86102L0.201427 1.88307C-0.271391 1.22121 0.201728 0.301786 1.01513 0.301785L10.9849 0.301785C11.7983 0.301785 12.2714 1.22121 11.7986 1.88307L6.8137 8.86103Z"
          fill="currentColor"
        />
      </svg>
    </>
  )
}

export default IconDropDown
