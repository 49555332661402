import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"

const Image = ({ src, alt, title, className, onClick }) => {
  return (
    <>
      <LazyLoadImage src={src} onClick={onClick} alt={alt} title={title} className={className} />
    </>
  )
}

export default Image
