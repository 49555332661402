export const getExchangeName = () =>
  JSON.parse(localStorage.getItem("okta-token-storage"))?.tokenDetails[
    "https://zero13.net/userdata"
  ]["exchange"] || "SECDEX";

export const api = {
  EXCHANGE: getExchangeName,
  METHOD_INSTRUMENT_SEGMENTS: "/instrument/segments",
  METHOD_INSTRUMENT_TYPES: "/instrument/types",
  METHOD_DOCUMENT_TYPES: "/document/types",
};
