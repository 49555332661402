import React from "react";

const OktaSignInWidget = () => {
  return (
    <div>
      <div
        id="hiw-login-container"
        data-testid="hiw-login-container"
        className="md:absolute md:-top-1/3 shadow-2xl bg-white rounded-md"
      />
    </div>
  );
};

export default OktaSignInWidget;
