import { api } from "../helper/config";
import InstrumentModel from "../models/InstrumentModel";
import axios from "axios";
import { getAPIHeader } from "./CommonService";

export const getInstruments = (params) => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/instruments?start-row=" +
      params.page;

    if (params.type !== "all") {
      url = url + "&type=" + params.type;
    }

    if (params.segment !== "all") {
      url = url + "&segment=" + params.segment;
    }

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        let pageDetails = response.data["page-details"];
        resolve({
          status: true,
          statusCode: response.status,
          data: InstrumentModel.parseList(response.data.data),
          totalRows: pageDetails["total-rows"],
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};

export const getInstrumentSegment = () => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      api.METHOD_INSTRUMENT_SEGMENTS;

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        resolve({
          status: true,
          data: InstrumentModel.parseSegment(response.data.data),
        });
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.message,
        });
      });
  });
};

export const getInstrumentTypes = () => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      api.METHOD_INSTRUMENT_TYPES;

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        resolve({
          status: true,
          data: InstrumentModel.parseSegment(response.data.data),
        });
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.message,
        });
      });
  });
};

export const getInstrumentDetail = (params) => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/instrument/" +
      params.id +
      "/detail";

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        resolve({
          status: true,
          statusCode: response.status,
          data: response.data.data
            ? InstrumentModel.parseDetail(response.data.data)
            : {
                banner: "",
                short: [],
                details: [],
              },
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};
