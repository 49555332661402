import { api } from "../helper/config";
import HoldingModel from "../models/HoldingModel";
import axios from "axios";
import { getAPIHeader, parseJWTDetail } from "./CommonService";

export const getGroups = () => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/participant/" +
      parseJWTDetail("participant") +
      "/groups?start-row=0&rows=2147483647";

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        let pageDetails = response.data["page-details"];
        resolve({
          status: true,
          statusCode: response.status,
          data: response.data.data
            ? HoldingModel.parseGroups(response.data.data)
            : [],
          totalRows: pageDetails["total-rows"],
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};

export const getAccounts = (params) => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/group/" +
      params.id +
      "/accounts?start-row=0&rows=2147483647";

    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        let pageDetails = response.data["page-details"];
        resolve({
          status: true,
          statusCode: response.status,
          data: response.data.data
            ? HoldingModel.parseAccount(response.data.data)
            : [],
          totalRows: pageDetails["total-rows"],
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};

export const getHoldings = (params) => {
  return new Promise((resolve, reject) => {
    let header = getAPIHeader();
    var url =
      window.env.REACT_APP_BASE_URI +
      api.EXCHANGE() +
      "/account/" +
      params.accounts.map(({ id }) => id).join(",") +
      "/holdings?start-row=" +
      params.page;
    if (params.sortBy) {
      if (params.sortBy === "asset.code") {
        url = url + "&sort-by=" + params.sortBy + "&order=DESC";
      } else {
        url = url + "&sort-by=" + params.sortBy;
      }
    }
    axios
      .get(url, {
        headers: header,
      })
      .then((response) => {
        let pageDetails = response.data["page-details"];
        resolve({
          status: true,
          statusCode: response.status,
          data: response.data.data
            ? HoldingModel.parseList(response.data.data, params.accounts)
            : [],
          totalRows: pageDetails["total-rows"],
        });
      })
      .catch((error) => {
        let statusCode = 404;
        if (error.response) {
          statusCode = error.response.status;
        }
        resolve({
          status: false,
          statusCode: statusCode,
          message: error.message,
        });
      });
  });
};
