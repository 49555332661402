import { createColumnHelper } from "@tanstack/react-table";
import IconDownload from "../assets/icons/IconDownload";
import { Tooltip } from "react-tooltip";

const columnHelper = createColumnHelper();

export const docColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
  }),
  columnHelper.accessor("account", {
    header: () => "Account",
  }),
  columnHelper.accessor("symbol", {
    header: () => "Symbol",
  }),
  columnHelper.accessor("type", {
    header: () => "Type",
  }),
  columnHelper.accessor("description", {
    header: () => "Description",
  }),
  columnHelper.accessor("size", {
    header: () => "Size",
  }),
  columnHelper.accessor("date", {
    header: () => "Date",
  }),
  columnHelper.accessor("action", {
    header: () => "",
    cell: (ss) => {
      return (
        <>
          <Tooltip id="document-url" place="top" variant="light" size="sm" />
          <a
            href={ss.row.original.download}
            rel="noreferrer"
            target="_blank"
            type="button"
          >
            <IconDownload
              className="size-6"
              strokeWidth={2}
              fileUrl={ss.row.original.download}
            />
          </a>
        </>
      );
    },
  }),
];

export const pubDocColumns = [
  columnHelper.accessor("id", {
    header: () => "ID",
  }),
  // columnHelper.accessor("symbol", {
  //   header: () => "Symbol",
  // }),
  columnHelper.accessor("type", {
    header: () => "Type",
  }),
  columnHelper.accessor("description", {
    header: () => "Description",
  }),
  columnHelper.accessor("size", {
    header: () => "Size",
  }),
  columnHelper.accessor("date", {
    header: () => "Date",
  }),
  columnHelper.accessor("action", {
    header: () => "",
    cell: (ss) => {
      return (
        <>
          <Tooltip id="document-url" place="top" variant="light" size="sm" />
          <a
            href={ss.row.original.download}
            rel="noreferrer"
            target="_blank"
            type="button"
          >
            <IconDownload
              className="size-6"
              strokeWidth={2}
              fileUrl={ss.row.original.download}
            />
          </a>
        </>
      );
    },
  }),
];

export const docData = [
  {
    id: "001",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "002",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "003",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "004",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "005",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "006",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "007",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
  {
    id: "008",
    account: "TA123",
    symbol: "Accounts",
    type: "2023 Accounts",
    description: "Filed Statements at CH",
    size: "10 Pages",
    date: "23 May 2023",
  },
];
