import React from "react";

const IconDownload = ({ className, strokeWidth = 1.5, fileUrl }) => {
  return (
    <>
      <svg
        data-tooltip-id="document-url"
        data-tooltip-content={fileUrl}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        className={`text-current hover:text-primary transition-all ${className}`}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
        />
      </svg>
    </>
  );
};

export default IconDownload;
